import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { GamingReportsTabs } from './GamingReportsTabs'
import { useParams } from 'react-router-dom'

export const GamingReportsHeader = ({
  currentTab,
  children,
}: {
  currentTab: string
  children?: React.ReactNode
}) => {
  const { id } = useParams()

  return (
    <Page
      header={
        <PageHeader
          title="View Gaming Reports"
          isSecondary={true}
          tabs={
            <GamingReportsTabs
              currentTab={currentTab}
              locationId={Number(id)}
            />
          }
        />
      }
    >
      {children}
    </Page>
  )
}
