import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { has, isNil, map, startsWith } from 'lodash'
import { useNavBar } from 'hooks/useNavBar'
import ChevronDown from 'assets/chevron-down.svg'
// import LiveLocationIcon from 'assets/LiveLocationIcon.svg'
import { useGetMe } from 'hooks/api/useGetMe'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  getLEPermissions,
  isPathAvailable,
} from 'src/navigation/pathPermissions'
import { useLayout } from 'hooks/useLayout'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { useMemo } from 'react'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { type NavBarDefWithChildren, type NavBarRouteDef } from 'src/types/nav'
import {
  type SnackbarState,
  useSideNavCollapseStore,
} from 'stores/useSideNavCollapseStore'
import { NAVIGATION_BAR_WIDTH, NavBarRoutes } from './NavigationBar'

export const isChildrenRouteDef = (
  x: NavBarRouteDef
): x is NavBarDefWithChildren => {
  return has(x, 'childrenRoutes')
}

export const Content = () => {
  const { isMobile } = useLayout()
  const location = useLocation()
  const navigate = useNavigate()
  const close = useNavBar((state) => state.close)
  const openCollapse = useSideNavCollapseStore((state) => state)
  const setOpenCollapse = useSideNavCollapseStore((state) => state.setOpen)
  const caId = useCurrentCorporateAccountStore().currentCorporateAccountId

  const checkLocation = (routes: string[]): boolean => {
    for (const route of routes) {
      if (startsWith(location.pathname, route)) {
        return true
      }
    }
    return false
  }

  const selectedCorporateAccount = useCurrentCorporateAccountStore()

  const { data: licensedEstablishments } =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId: Number(
        selectedCorporateAccount.currentCorporateAccountId
      ),
    })

  // Check LEs for licenses with "Expired" status
  const expiredLicenses = useMemo(() => {
    if (licensedEstablishments && Array.isArray(licensedEstablishments)) {
      return licensedEstablishments.some(
        (le) =>
          le.licensedEstablishmentLicenses?.some(
            (license) =>
              license.licensedEstablishmentLicenseType !== 'Gaming' &&
              license.licenseStatus === 'Expired'
          )
      )
    }
  }, [licensedEstablishments])

  const leUserQuery = useGetMe()
  const user: LicensedEstablishmentUser | undefined = leUserQuery?.data?.user

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    leUserQuery?.data?.licensedEstablishments,
    leUserQuery?.data?.user?.licensedEstablishments,
    caId
  )

  if (leUserQuery.isPending) {
    return <ActivityIndicator />
  }

  if (isNil(user)) {
    throw new Error('J&J Connect user is undefined')
  }

  // Check all routes and child routes for permissions and return only the ones that are allowed
  const availableNavRoutes = Object.entries(NavBarRoutes)
    .map(([routeName, routeData]) => ({
      routeName,
      ...routeData,
    }))
    .filter((route) => {
      const isPathAllowed = isPathAvailable(route.path, lePermissions)
      if (isChildrenRouteDef(route)) {
        route.childrenRoutes = route.childrenRoutes.filter((childRoute) =>
          isPathAvailable(childRoute.path, lePermissions)
        )
      }
      return (
        isPathAllowed ||
        (isChildrenRouteDef(route) && route.childrenRoutes.length > 0)
      )
    })

  return (
    <List
      sx={{
        ml: isMobile ? -0.8 : 0,
        backgroundColor: 'white',
        flex: 1,
        width: isMobile
          ? `${NAVIGATION_BAR_WIDTH * 1.35}px`
          : `${NAVIGATION_BAR_WIDTH}px`,
        borderRightWidth: 1,
      }}
    >
      {map(availableNavRoutes, (routeInfo, key) => {
        if (isChildrenRouteDef(routeInfo)) {
          return (
            <Box key={'children-routes-box' + key}>
              <ListItem
                sx={{ px: 1, py: 0.5 }}
                key={'children-routes-listitem' + key}
              >
                <ListItemButton
                  key={'children-routes-button' + key}
                  onClick={() => setOpenCollapse(key.toString())}
                >
                  <ListItemIcon
                    key={'children-routes-icon' + key}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {routeInfo.icon}
                  </ListItemIcon>
                  <ListItemText
                    key={'children-routes-text' + key}
                    primary={
                      <Typography variant="body2">
                        {routeInfo.routeName}
                      </Typography>
                    }
                  />
                  <img
                    src={ChevronDown}
                    alt="Chevron Down"
                    className={
                      openCollapse[key as unknown as keyof SnackbarState]
                        ? 'rotate-180'
                        : '' + 'pl-2'
                    }
                    key={'children-routes-img' + key}
                  />
                </ListItemButton>
              </ListItem>
              <Collapse
                in={
                  openCollapse[key as unknown as keyof SnackbarState] as boolean
                }
                timeout="auto"
                unmountOnExit
                key={'children-routes-collapse' + key}
              >
                <List
                  component="div"
                  disablePadding
                  key={'children-routes-collapse-list' + key}
                >
                  {map(routeInfo.childrenRoutes, (childRoute) => {
                    return (
                      <ListItem
                        sx={{ px: 1, py: 0.5 }}
                        key={'children-routes-listitem2' + childRoute.path}
                      >
                        <ListItemButton
                          key={'children-routes-listbutton' + childRoute.path}
                          onClick={async () => {
                            await navigate(childRoute.path)
                            close()
                          }}
                          selected={
                            startsWith(location.pathname, childRoute.path) ||
                            checkLocation(childRoute.associatedPageUrls)
                          }
                        >
                          <ListItemIcon
                            key={'children-routes-listicon' + childRoute.path}
                            sx={{
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          ></ListItemIcon>
                          <ListItemText
                            sx={{ px: 0.25 }}
                            key={'children-routes-listtext' + childRoute.path}
                            primary={
                              <Typography variant="label-listSubItem">
                                {childRoute.title}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </Collapse>
            </Box>
          )
        } else {
          return (
            <ListItem
              sx={{ px: 1, py: 0.5 }}
              key={'listitem-standard-routes-listitem' + key}
            >
              <ListItemButton
                key={'listitem-standard-routes-button' + key}
                onClick={async () => {
                  await navigate(routeInfo.path)
                  close()
                }}
                selected={startsWith(location.pathname, routeInfo.path)}
              >
                <ListItemIcon
                  key={'listitem-standard-routes-icon' + key}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {routeInfo.icon}
                </ListItemIcon>
                <ListItemText
                  key={'listitem-standard-routes-text' + key}
                  primary={
                    <Typography variant="label-listItem">
                      {routeInfo.routeName}
                    </Typography>
                  }
                />
                {expiredLicenses && (
                  <ListItemIcon
                    key={'children-routes-icon' + key}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {routeInfo.secondaryIcon}
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          )
        }
      })}
    </List>
  )
}
