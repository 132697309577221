import { random } from 'lodash'
import { useGetRewardRedemptionByRewardProgram } from 'hooks/api/Rewards/useGetRewardRedemptionByRewardProgram'
import { DataTable } from 'components/Shared/DataTable'
import { RedemptionColumns } from './RedemptionColumns'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const RedemptionSection = ({
  rewardProgramId,
  daysDisplayed,
}: {
  rewardProgramId: number
  daysDisplayed: string
}) => {
  const redemptionQuery = useGetRewardRedemptionByRewardProgram({
    rewardProgramId,
    startTransactionDate: new Date(
      new Date().getTime() - Number(daysDisplayed) * 24 * 60 * 60 * 1000
    ).toLocaleString(),
    endTransactionDate: new Date().toLocaleString(),
  })

  if (redemptionQuery.isError) {
    return <p>An error occurred.</p>
  }

  let RedemptionColumnsModified = RedemptionColumns
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const leData =
    meQuery?.data?.user?.licensedEstablishments?.find(
      (x) =>
        x.licensedEstablishmentId ===
        meQuery?.data?.licensedEstablishments?.find(
          (x) => x.corporateAccountId === selectedCorporateAccountId
        )?.id
    ) ?? undefined

  const showPatronName = leData?.reportsAccessToPIIPermission
  if (!showPatronName) {
    RedemptionColumnsModified = RedemptionColumns.slice(1)
  }

  return (
    <DataTable
      sortFieldName="patronId"
      sort="asc"
      columns={RedemptionColumnsModified}
      rows={
        redemptionQuery.data?.map((redemption) => {
          let displayPatronName = ''
          if (showPatronName) {
            displayPatronName =
              redemption.patron?.firstName + ' ' + redemption.patron?.lastName
          }
          return {
            patronId: redemption.patronId,
            patronName: displayPatronName,
            rewardItemId: redemption.rewardsCatalogReward?.rewardId,
            rewardItemName: redemption.rewardsCatalogReward?.reward?.name,
            licensedEstablishmentName:
              redemption?.licensedEstablishment?.standardName,
            pointCost: redemption.rewardsCatalogReward?.amount,
            timeOfredemption: redemption.createdOn,
            rewardProgramName: redemption.rewardsProgram?.name,
          }
        }) ?? []
      }
      getRowId={() => random(0, 1000, true)}
      style={{ maxWidth: 1580 }}
    ></DataTable>
  )
}
