import { LicensesContact } from 'pages/LicensedEstablishmentLicenses/LicensesContact'
import { Box, Typography, useTheme } from '@mui/material'
import { LicensesListLicensedEstablishmentPage } from 'pages/LicensedEstablishmentLicenses/LicensesListLicensedEstablishmentPage'

export const LicensesLicensedEstablishment = () => {
  const theme = useTheme()

  return (
    <Box px={0} sx={{ backgroundColor: theme.palette.secondary.light }}>
      <Box
        px={{ xs: '16px', sm: '41px' }}
        pt={{ xs: '21px', sm: '32px' }}
        pb={'1%'}
      >
        <Typography variant="h4">Licenses</Typography>
        <LicensesListLicensedEstablishmentPage />
        <LicensesContact />
      </Box>
    </Box>
  )
}
