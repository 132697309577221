import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import { type NewLicensedEstablishmentUser } from 'src/types/api'
import { useJJConnectUserSchema } from './useJJConnectUserSchema'
import { useApiMutation } from 'hooks/useApiMutation'

interface usePostJJConnectUserProps {
  onSuccess?: (data: NewLicensedEstablishmentUser) => Promise<void>
  onError?: (error: ApiError) => Promise<void>
}

export const usePostJJConnectUser = ({
  onSuccess,
  onError,
}: usePostJJConnectUserProps) => {
  const post = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/create',
    method: 'post',
  })
  const { schema } = useJJConnectUserSchema()

  return useApiMutation<typeof post, typeof schema>({
    mutationFn: async (formData) => {
      return await post({
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        type: 'Staff',
        corporateAccountId: formData.corporateAccountId ?? null,
      })
    },
    onSuccess: async (result) => {
      await onSuccess?.(result)
    },
    onError,
  })
}
