import { compact, isNil } from 'lodash'
import { Box, Button } from '@mui/material'
import { SweepstakesDetailsHeader } from '../SweepstakeDetailsHeader'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { SweepstakesDetailsSection } from './SweepstakeDetailsSection'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { SweepstakeImageSection } from './SweepstakeImageSection'
import { SweepstakeParticipatingGuidelineSection } from './SweepstakeParticipatingGuidelineSection'
import { useNumberParam } from 'hooks/useNumberParam'
import { useMemo } from 'react'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetContestLicensedEstablishment } from 'hooks/api/useGetContestLicensedEstablishment'
import { useGetParticipatingLocations } from 'hooks/useGetParticipatingLocations'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { useNavigate } from 'react-router-dom'

export const SweepstakeOverviewPage = () => {
  const navigate = useNavigate()
  const sweepstakeId = useNumberParam('id')
  const { setParticipatingLocations, setEnrollmentDetails } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()

  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })
  const { currentCorporateAccountId } = useCurrentCorporateAccountStore()

  const leByCorporateAccountQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId: Number(currentCorporateAccountId),
    })

  const licensedEstablishmentSearchQuery = useGetContestLicensedEstablishment({
    contestId: sweepstakeId,
  })

  const contestLes = licensedEstablishmentSearchQuery.data

  const { licensedEstablishments, isPending } = useGetParticipatingLocations({
    contestLes,
    currentCorporateAccountId,
  })

  const availableLesInaCa = useMemo(() => {
    return compact(
      (leByCorporateAccountQuery.data ?? []).filter(
        (x) => x.corporateAccountId === currentCorporateAccountId
      )
    )
  }, [leByCorporateAccountQuery.data])

  const buttonMessage = useMemo(() => {
    if (
      licensedEstablishments !== undefined &&
      availableLesInaCa !== undefined
    ) {
      const currentlyEnrolledLesLength = licensedEstablishments.length
      const availableLesLength = availableLesInaCa.length

      if (currentlyEnrolledLesLength === availableLesLength) {
        return 'All My Locations Enrolled'
      }
      if (
        currentlyEnrolledLesLength < availableLesLength &&
        currentlyEnrolledLesLength > 0
      ) {
        return 'Enroll Additional Locations'
      }

      return 'Enroll in Sweepstakes'
    }
  }, [availableLesInaCa, licensedEstablishments])

  if (
    sweepstakeQuery.isPending ||
    isPending ||
    leByCorporateAccountQuery.isPending ||
    licensedEstablishmentSearchQuery.isPending
  ) {
    return <ActivityIndicator />
  }

  if (
    sweepstakeQuery.isError ||
    isNil(sweepstakeQuery.data) ||
    leByCorporateAccountQuery.isError
  ) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  const sweepstake = sweepstakeQuery.data

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Sweepstakes/${sweepstake.id}/Overview`}
      actionButton={
        sweepstake.sweepstakeData?.state === 'Enrollable' && (
          <Button
            sx={{ mr: 1 }}
            onClick={async () => {
              setParticipatingLocations(undefined)
              setEnrollmentDetails(undefined)
              await navigate(
                `/Sweepstakes/${sweepstakeQuery?.data?.id}/EnrollLocations`
              )
            }}
            variant="contained"
            disabled={buttonMessage === 'All My Locations Enrolled'}
          >
            {buttonMessage}
          </Button>
        )
      }
    >
      <Box>
        <SweepstakesDetailsSection sweepstake={sweepstake} />
        <SweepstakeParticipatingGuidelineSection sweepstake={sweepstake} />
        <SweepstakeImageSection sweepstake={sweepstake} />
      </Box>
    </SweepstakesDetailsHeader>
  )
}
