import { isPresent } from '@jjvgaming/player-payback-library'
import {
  Button,
  capitalize,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  FormFieldMessage,
  FormFieldMessageVariant,
} from 'components/FormFieldMessage/FormFieldMessage'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import {
  defaultValues,
  type JJConnectFormFields,
} from 'hooks/api/JJConnectUser/JJConnectFormFields'
import { useCallback, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useSnackbar } from 'stores/useSnackbar'
import ErrorIcon from 'assets/errorIcon.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { yupResolver } from '@hookform/resolvers/yup'
import { useJJConnectUserSchema } from 'hooks/api/JJConnectUser/useJJConnectUserSchema'
import { usePostJJConnectUser } from 'hooks/api/JJConnectUser/usePostJJConnectUser'
import { ApiError } from 'openapi-typescript-fetch'
import { usePutJJConnectUser } from 'hooks/api/JJConnectUser/usePutJJConnectUser'

export const NewLEConnectUserPage = () => {
  const { schema } = useJJConnectUserSchema()
  const formMethods = useForm<JJConnectFormFields>({
    defaultValues,
    resolver: yupResolver(schema, {}),
    mode: 'all',
    criteriaMode: 'all',
  })

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = formMethods

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const navigate = useNavigate()

  const setMessage = useSnackbar((state) => state.setMessage)

  const getLEUserEmail = useTypedApiClient({
    path: '/le-connect/licensed-establishments/users/emailmatch',
    method: 'get',
  })

  const queryClient = useQueryClient()

  const [emailData, setEmailData] = useState<JJConnectFormFields>()

  useEffect(() => {
    reset(emailData)
  }, [emailData])

  const emailExists = useCallback(
    async (email: string) => {
      if (!email) {
        setEmailData(undefined)
        return true
      }
      try {
        const patronEmailExistsQuery = await queryClient.fetchQuery({
          queryKey: [
            '/le-connect/licensed-establishments/users/emailmatch',
            email,
          ],
          queryFn: async () => {
            return await getLEUserEmail({
              email,
            })
          },
        })
        setEmailData(patronEmailExistsQuery.data)
        return patronEmailExistsQuery
      } catch (error: any) {
        if (error.status !== 401) {
          setMessage(error.data, 'error')
        }
        setEmailData(undefined)
        return error
      }
    },
    [getLEUserEmail, queryClient]
  )

  const postLicensedEstablishmentUserMutation = usePostJJConnectUser({
    onSuccess: async () => {
      setMessage('J&J Connect User successfully created', 'success')
      await navigate(`/LicensedEstablishmentUsers`)
    },
    onError: async (e) => {
      if (e instanceof ApiError) {
        setMessage(e.data, 'error')
      } else {
        setMessage("Couldn't create J&J Connect User", 'error')
      }
    },
  })

  const putLicensedEstablishmentUserMutation = usePutJJConnectUser({
    licensedEstablishmentUserId: Number(emailData?.id),
    corporateAccountId: Number(selectedCorporateAccountId),
    onSuccess: async () => {
      setMessage('J&J Connect User successfully updated', 'success')
      await navigate(`/LicensedEstablishmentUsers`)
    },
    onError: async (e) => {
      if (e instanceof ApiError) {
        setMessage(e.data, 'error')
      } else {
        setMessage("Couldn't update J&J Connect User", 'error')
      }
    },
  })

  const path = '/le-connect/licensed-establishments/users/{id}'
  const getLEUserEmail2 = useTypedApiClient({
    path,
    method: 'get',
  })

  const handleSubmitWrapper = handleSubmit(
    async (data: JJConnectFormFields) => {
      let leUser
      let corporateAccountIds

      if (emailData) {
        const licensedEstablishmentUserQuery = await queryClient.fetchQuery({
          queryKey: [path, Number(emailData?.id)],
          queryFn: async () => {
            return await getLEUserEmail2({
              id: Number(emailData?.id),
            })
          },
        })

        leUser = licensedEstablishmentUserQuery.data

        corporateAccountIds =
          leUser?.licensedEstablishmentUserCorporateAccounts?.map(
            (x) => x.corporateAccountId
          ) ?? []

        if (!corporateAccountIds.includes(Number(selectedCorporateAccountId))) {
          corporateAccountIds.push(Number(selectedCorporateAccountId))
        }
      }

      data.corporateAccountId = Number(selectedCorporateAccountId)
      emailData?.id
        ? putLicensedEstablishmentUserMutation.mutate({
            ...leUser,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            type: 'Staff',
            corporateAccountIds,
          })
        : postLicensedEstablishmentUserMutation.mutate(data)
    }
  )

  if (!selectedCorporateAccountId) {
    return (
      <Page
        header={
          <PageHeader
            title="J&J Connect Users"
            isSecondary={true}
            backPath="/LicensedEstablishmentUsers"
            backText="J&J Connect Users"
          />
        }
      >
        <Typography>An error occurred</Typography>
      </Page>
    )
  }
  return (
    <FormProvider {...formMethods}>
      <Page
        header={
          <PageHeader
            title="Create a New J&J Connect User"
            subtitle={
              'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
            }
            isSecondary={true}
            backPath="/LicensedEstablishmentUsers"
            backText="J&J Connect Users"
          ></PageHeader>
        }
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmitWrapper}>
            <Stack gap={2} maxWidth={500}>
              <InputLabel required htmlFor="LEUser.firstName" sx={{ mb: 0 }}>
                Contact Information
              </InputLabel>
              <Controller
                name="email"
                render={({ field: { onBlur, onChange, value } }) => (
                  <TextField
                    name="email"
                    placeholder="Enter your Email"
                    type="email"
                    error={isPresent(errors.email)}
                    autoCorrect="false"
                    spellCheck={false}
                    autoComplete="true"
                    value={value}
                    onBlur={onBlur}
                    onChange={async (e) => {
                      onChange(e)
                      await emailExists(e.target.value)
                    }}
                  />
                )}
              />
              {isPresent(errors.email) && isPresent(errors.email.message) && (
                <FormFieldMessage
                  message={capitalize(errors.email.message)}
                  variant={FormFieldMessageVariant.Error}
                  icon={ErrorIcon}
                />
              )}
              <Stack
                gap={2}
                direction={{ xs: 'column', sm: 'row' }}
                width={'100%'}
              >
                <Stack gap={1} width={'100%'}>
                  <Controller
                    name="firstName"
                    render={({ field: { onBlur, onChange, value } }) => (
                      <TextField
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        onBlur={onBlur}
                        error={isPresent(errors.firstName)}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        disabled={emailData !== undefined}
                        value={emailData?.firstName ?? value}
                      />
                    )}
                  />
                  {isPresent(errors.firstName) &&
                    isPresent(errors.firstName.message) && (
                      <FormFieldMessage
                        message={capitalize(errors.firstName.message)}
                        variant={FormFieldMessageVariant.Error}
                        icon={ErrorIcon}
                      />
                    )}
                </Stack>
                <Stack gap={1} width={'100%'}>
                  <Controller
                    name="lastName"
                    render={({ field: { onBlur, onChange, value } }) => (
                      <TextField
                        name="Last Name"
                        placeholder="Last Name"
                        type="text"
                        onBlur={onBlur}
                        error={isPresent(errors.lastName)}
                        onChange={(e) => {
                          onChange(e)
                        }}
                        disabled={emailData !== undefined}
                        value={emailData?.lastName ?? value}
                      />
                    )}
                  />
                  {isPresent(errors.lastName) &&
                    isPresent(errors.lastName.message) && (
                      <FormFieldMessage
                        message={capitalize(errors.lastName.message)}
                        variant={FormFieldMessageVariant.Error}
                        icon={ErrorIcon}
                      />
                    )}
                </Stack>
              </Stack>

              <Stack direction="row" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  onClick={async () =>
                    await navigate(`/LicensedEstablishmentUsers`)
                  }
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Page>
    </FormProvider>
  )
}
