import { Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link, useNavigate } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { getLEPermissions } from 'src/navigation/pathPermissions'
import { useSetLe } from 'stores/useSetLe'
import { useEffect } from 'react'

export const GamingReportsTabs = ({
  currentTab,
  locationId,
}: {
  currentTab: string
  locationId: number
}) => {
  const navigate = useNavigate()
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  const financialPermission = lePermissions?.[0]?.reportsFinancialPermission

  const setPassedLe = useSetLe((state) => state.setPassedLe)
  const { passedLe } = useSetLe()

  useEffect(() => {
    if (passedLe?.id === undefined) {
      void navigate('/GamingReports')
    }
  }, [passedLe])

  const gamingReportsTabs = [
    {
      label: 'Credits Wagered by Machine',
      value: `1`,
      to: `/GamingReports/CreditsWageredByMachine`,
    },
    {
      label: 'Credits Wagered by Hour',
      value: `2`,
      to: `/GamingReports/CreditsWageredByHour`,
    },
    {
      label: 'Credits Wagered: Hourly Trends',
      value: `3`,
      to: `/GamingReports/CreditsWageredHourlyTrends`,
    },
    {
      label: 'Weekly Snapshot',
      value: '',
      to: `/GamingReports/WeeklySnapshotLocationSelect`,
    },
    {
      label: 'NTI Trends',
      value: `4`,
      to: `/GamingReports/TrailingNtiTrends`,
    },
    {
      label: 'VGT Snapshot',
      value: `5`,
      to: `/GamingReports/VgtSnapshot`,
    },
    {
      label: 'VGT Trends',
      value: `6`,
      to: `/GamingReports/VgtTrends`,
    },
  ]

  return (
    <div>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={(selectedLe: LicensedEstablishment) => {
          // Update the passed LE
          setPassedLe(selectedLe)
        }}
        locationId={passedLe?.id}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label="Overview"
          value="/GamingReports/GamingReportsOverview"
          to="/GamingReports"
          component={Link}
        />
        {financialPermission &&
          gamingReportsTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
      </Tabs>
    </div>
  )
}
