import { PlayerPaybackReportsHeader } from './PlayerPaybackReportsHeader'
import { PlayerPaybackReportsOverview } from './PlayerPaybackReportsOverview'

export const PlayerPaybackReportsPage = () => {
  return (
    <PlayerPaybackReportsHeader
      currentTab={'/PlayerPaybackReports/PlayerPaybackReportsOverview'}
    >
      <PlayerPaybackReportsOverview />
    </PlayerPaybackReportsHeader>
  )
}
