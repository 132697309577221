import { Box, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { WeeklySnapshotTable } from './WeeklySnapshotTable'
import { useGetMe } from 'hooks/api/useGetMe'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { sub, startOfToday, format } from 'date-fns'
import { PageHeader } from 'components/Shared/PageHeader'
import { useLocation } from 'react-router-dom'
import { useGetMultiLocationWeeklySnapshot } from 'hooks/api/Reports/useGetMultiLocationWeeklySnapshot'
import { colorPrimitives } from 'components/Theme'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { useMemo } from 'react'

export const WeeklySnapshotTablePage = () => {
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const location = useLocation()
  const selectedLicenseNumbers = location.state?.selectedLicenseNumbers

  const today = startOfToday()
  const eightWeeksAgo = sub(today, { days: 57 })

  const weeklySnapshotQuery = useGetMultiLocationWeeklySnapshot({
    licenses: selectedLicenseNumbers,
    startDate: eightWeeksAgo,
    endDate: today,
  })

  const formattedStartDate = format(sub(today, { days: 7 }), 'MM/dd/yyyy')
  const formattedEndDate = format(sub(today, { days: 1 }), 'MM/dd/yyyy')

  const locationCountMessage =
    selectedLicenseNumbers.length === 1
      ? 'Viewing data for 1 location'
      : `Viewing data for ${selectedLicenseNumbers.length} locations`

  const selectedLicensedEstablishments = useMemo(() => {
    return licensedEstablishments?.filter((le) =>
      selectedLicenseNumbers.includes(le.licenseNumber)
    )
  }, [licensedEstablishments, selectedLicenseNumbers])

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Weekly Snapshot"
            backText="Location Select"
            subtitle={`Last 7 Days: ${formattedStartDate} - ${formattedEndDate}`}
            backPath="/GamingReports"
            isSecondary={true}
          />
        </Box>
      }
    >
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          <Typography variant="h6" color={colorPrimitives.darkGray}>
            {locationCountMessage}
          </Typography>

          {meQuery.isPending && <ActivityIndicator />}
          {weeklySnapshotQuery.isPending && weeklySnapshotQuery.isFetching && (
            <ActivityIndicator />
          )}
          {meQuery.isError && <ErrorIndicator />}
          {weeklySnapshotQuery.isError && <ErrorIndicator />}
          {weeklySnapshotQuery.data &&
            (weeklySnapshotQuery.data.metrics.length === 0 ? (
              <Typography variant="h4"></Typography>
            ) : (
              <WeeklySnapshotTable data={weeklySnapshotQuery.data.metrics} />
            ))}
          <Box>
            <Typography variant="h3">Locations</Typography>
            {selectedLicensedEstablishments?.map((le) => (
              <Typography key={le.id} variant="body1">
                {le.standardName} (#{le.licenseNumber})
              </Typography>
            ))}
          </Box>
        </Stack>
      )}
    </Page>
  )
}
