import { useNavigate, useParams } from 'react-router-dom'
import { SweepstakesDetailsHeader } from '../../SweepstakeDetailsHeader'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { compact, isNil } from 'lodash'
import { Box, Button, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { ParticipatingLocationList } from './ParticipatingLocationList'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useApiQuery } from 'hooks/useApiQuery'
import { useGetContestLicensedEstablishment } from 'hooks/api/useGetContestLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useGetLicensedEstablishmentByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentByCorporateAccount'
import { useGetParticipatingLocations } from 'hooks/useGetParticipatingLocations'

export const SweepstakeParticipatingLocationsPage = () => {
  const navigate = useNavigate()
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const { currentCorporateAccountId, currentCorporateAccountName } =
    useCurrentCorporateAccountStore()

  const sweepstakeQuery = useApiQuery({
    path: '/le-connect/contests/{id}',
    method: 'get',
    queryKey: [
      '/le-connect/contests/{id}',
      sweepstakeId,
      currentCorporateAccountId,
    ],
    queryArgs: { id: sweepstakeId },
    enabled: sweepstakeId != null,
  })

  const leByCorporateAccountQuery =
    useGetLicensedEstablishmentByCorporateAccount({
      corporateAccountId: Number(currentCorporateAccountId),
    })

  const licensedEstablishmentSearchQuery = useGetContestLicensedEstablishment({
    contestId: sweepstakeId,
  })

  const { setParticipatingLocations, setEnrollmentDetails } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()

  const sweepstake = sweepstakeQuery.data
  const contestLes = licensedEstablishmentSearchQuery.data

  const {
    corporateAccounts,
    licensedEstablishments,
    organizations,
    isPending,
  } = useGetParticipatingLocations({ contestLes, currentCorporateAccountId })

  const availableLesInaCa = useMemo(() => {
    return compact(
      (leByCorporateAccountQuery.data ?? []).filter(
        (x) => x.corporateAccountId === currentCorporateAccountId
      )
    )
  }, [leByCorporateAccountQuery.data])

  const buttonMessage = useMemo(() => {
    if (
      licensedEstablishments !== undefined &&
      availableLesInaCa !== undefined
    ) {
      const currentlyEnrolledLesLength = licensedEstablishments.length
      const availableLesLength = availableLesInaCa.length

      if (currentlyEnrolledLesLength === availableLesLength) {
        return 'All My Locations Enrolled'
      }
      if (
        currentlyEnrolledLesLength < availableLesLength &&
        currentlyEnrolledLesLength > 0
      ) {
        return 'Enroll Additional Locations'
      }

      return 'Enroll in Sweepstakes'
    }
  }, [availableLesInaCa, licensedEstablishments])

  if (
    sweepstakeQuery.isPending ||
    licensedEstablishmentSearchQuery.isPending ||
    leByCorporateAccountQuery.isPending ||
    isPending
  ) {
    return <ActivityIndicator />
  }

  if (
    sweepstakeQuery.isError ||
    licensedEstablishmentSearchQuery.isError ||
    isNil(sweepstake) ||
    leByCorporateAccountQuery.isError
  ) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  return (
    <SweepstakesDetailsHeader
      sweepstake={sweepstake}
      currentTab={`/Sweepstakes/${sweepstake?.id}/ParticipatingLocations`}
      actionButton={
        sweepstake.sweepstakeData?.state === 'Enrollable' && (
          <Button
            sx={{ mr: 1 }}
            onClick={async () => {
              setParticipatingLocations(undefined)
              setEnrollmentDetails(undefined)
              await navigate(
                `/Sweepstakes/${sweepstakeQuery?.data?.id}/EnrollLocations`
              )
            }}
            variant="contained"
            disabled={buttonMessage === 'All My Locations Enrolled'}
          >
            {buttonMessage}
          </Button>
        )
      }
    >
      <Typography variant="h2">
        My Participating {currentCorporateAccountName} Locations
      </Typography>

      {sweepstakeQuery.isPending ? (
        <ActivityIndicator marginTop={8} />
      ) : (
        <ParticipatingLocationList
          licensedEstablishments={
            licensedEstablishments as LicensedEstablishment[]
          }
          corporateAccounts={corporateAccounts}
          organizations={organizations}
        />
      )}
      {(sweepstakeQuery.isError || isNil(sweepstake)) ?? (
        <Box>An error occurred fetching the sweepstake.</Box>
      )}
    </SweepstakesDetailsHeader>
  )
}
