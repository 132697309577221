import { Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link, useNavigate } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { getLEPermissions } from 'src/navigation/pathPermissions'
import { useSetLe } from 'stores/useSetLe'
import { useEffect } from 'react'

export const PlayerPaybackReportsTabs = ({
  currentTab,
  locationId,
}: {
  currentTab: string
  locationId: number
}) => {
  const navigate = useNavigate()
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  const financialPermission = lePermissions?.[0]?.reportsFinancialPermission
  const nonFinancialPermission =
    lePermissions?.[0]?.reportsNonFinancialPermission

  const setPassedLe = useSetLe((state) => state.setPassedLe)
  const { passedLe } = useSetLe()

  useEffect(() => {
    if (passedLe?.id === undefined) {
      void navigate('/PlayerPaybackReports')
    }
  }, [passedLe])

  const nonFinancialTabs = [
    {
      label: 'Patron Activity',
      value: `1`,
      to: `/PlayerPaybackReports/PatronActivity`,
    },
  ]

  const financialTabs = [
    {
      label: 'Patron Check-ins',
      value: `2`,
      to: `/PlayerPaybackReports/PatronCheckins`,
    },
    {
      label: 'Patron List',
      value: `3`,
      to: `/PlayerPaybackReports/PatronList`,
    },
    {
      label: 'Patron Reward Redemptions',
      value: `4`,
      to: `/PlayerPaybackReports/PatronRewardRedemptions`,
    },
  ]

  return (
    <div>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={(selectedLe: LicensedEstablishment) => {
          // Update the passed LE
          setPassedLe(selectedLe)
        }}
        locationId={passedLe?.id}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label="Overview"
          value="/PlayerPaybackReports/PlayerPaybackReportsOverview"
          to="/PlayerPaybackReports"
          component={Link}
        />
        {nonFinancialPermission &&
          nonFinancialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
        {financialPermission &&
          financialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
      </Tabs>
    </div>
  )
}
