import { useGetHourlyCheckinsReport } from 'hooks/api/Reports/useGetHourlyCheckinsReport'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { add, sub, startOfToday } from 'date-fns'
import { PatronActivityChart } from './PatronActivityChart'
import { Stack } from '@mui/material'
import { PlayerPaybackReportsHeader } from '../PlayerPaybackReportsHeader'
import { useSetLe } from 'stores/useSetLe'

export const PatronActivityPage = () => {
  const { passedLe } = useSetLe()
  const today = startOfToday()
  const tomorrow = add(today, { days: 1 })
  const sevenDaysAgo = sub(today, { days: 7 })

  const reportQuery = useGetHourlyCheckinsReport({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate: sevenDaysAgo,
    endDate: tomorrow,
  })

  return (
    <PlayerPaybackReportsHeader currentTab={'1'}>
      <Stack spacing={4}>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data && <PatronActivityChart data={reportQuery.data} />}
      </Stack>
    </PlayerPaybackReportsHeader>
  )
}
