import { useEffect } from 'react'
import { Box, Grid, FormControl, MenuItem, Select, styled } from '@mui/material'
import { CheckedInPatronsTable } from 'components/CheckedInPatronsTable/CheckedInPatronsTable'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { DatePicker } from '@mui/x-date-pickers'
import { Colors } from 'components/Theme'
import { uniqBy } from 'lodash'
import { useGetMe } from 'hooks/api/useGetMe'
import { usePatronCheckInsDateRangeState } from 'stores/usePatronCheckInsDateRange'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { getLEPermissions } from 'src/navigation/pathPermissions'

const StyledDatePicker = styled(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: '1rem',
    fontWeight: '100',
    color: Colors.muiLabels.placeholder,
  },
  '& .Mui-focused': {
    color: !Colors.muiLabels.placeholder,
  },
})

export const PatronAccountsPage = () => {
  const {
    startDate,
    endDate,
    licensedEstablishmentId,
    setStartDate,
    setEndDate,
    setLicensedEstablishmentId,
  } = usePatronCheckInsDateRangeState()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const meQuery = useGetMe()

  useEffect(() => {
    // when changing corporate accounts, set LE filter back to "All locations"
    setLicensedEstablishmentId(-1)
  }, [selectedCorporateAccountId])

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Patron Check Ins"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!lePermissions?.[0]?.patronsAccountsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view patron accounts. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          <Box className="py-4 flex flex-row gap-4 xs:justify-between">
            <StyledDatePicker
              disableFuture
              onChange={(date: any) => {
                setStartDate(date.setHours(0, 0, 0, 0))
              }}
              label="Start Date"
              value={startDate}
            />
            <StyledDatePicker
              onChange={(date: any) => {
                setEndDate(date.setHours(23, 59, 59, 0))
              }}
              label="End Date"
              value={endDate}
            />
            <FormControl>
              <Select
                displayEmpty
                onChange={(event) => {
                  setLicensedEstablishmentId(Number(event.target.value))
                }}
                value={licensedEstablishmentId}
              >
                <MenuItem value={-1}>All locations</MenuItem>
                {uniqBy(
                  meQuery.data?.licensedEstablishments?.filter(
                    (le) => le.corporateAccountId === selectedCorporateAccountId
                  ),
                  'id'
                )?.map((le) => {
                  return (
                    <MenuItem key={le.id} value={le.id}>
                      {le.standardName} (#{le.licenseNumber})
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <Grid container columnGap={24}>
            <Grid item sm={12}>
              <CheckedInPatronsTable />
            </Grid>
          </Grid>
        </>
      )}
    </Page>
  )
}
