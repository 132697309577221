import { Box, Button, Typography } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import CheckIcon from 'assets/check_circle.svg'
import { useNavigate } from 'react-router-dom'

export const ConfirmationSection = () => {
  const navigate = useNavigate()
  return (
    <Box className="flex justify-center flex-col w-full items-center">
      <img src={CheckIcon} width="69px" />
      <Box className="pt-8">
        <Typography variant="h1" textAlign="center">
          Complete!
        </Typography>
      </Box>
      <Box className="py-8">
        <Typography variant="body-1" color={colorPrimitives.darkGray}>
          Welcome! You&apos;ve completed your account creation for J&J Gaming |
          J&J Connect. Sign in and head over to the dashboard.
        </Typography>
      </Box>
      <Button
        sx={{ width: '100%' }}
        variant="contained"
        onClick={async () => {
          await navigate('/')
        }}
      >
        <Typography variant="label-CTA">Go To Sign In</Typography>
      </Button>
    </Box>
  )
}
