import { GamingReportsHeader } from './GamingReportsHeader'
import { GamingReportsOverview } from './GamingReportsOverview'

export const GamingReportsPage = () => {
  return (
    <GamingReportsHeader currentTab={'/GamingReports/GamingReportsOverview'}>
      <GamingReportsOverview />
    </GamingReportsHeader>
  )
}
