import { LoginPage } from 'pages/Login/LoginPage'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom'
import { allPaths, type Path } from './paths'
import { PatronAccountsPage } from 'pages/PatronAccounts/PatronAccountsPage'
import { HomePage } from 'pages/Home/HomePage'
import { LocationsPage } from 'pages/Locations/LocationsPage'
import { WeeklySnapshotTablePage } from 'pages/GamingReports/WeeklySnapshot/WeeklySnapshotTablePage'
import { RewardsPage } from 'pages/Rewards/RewardsPage'
import { PromotionsPage } from 'pages/Promotions/PromotionsPage'
import { PromotionDetailsPage } from 'pages/Promotions/PromotionDetailsPage/PromotionsDetailsPage'
import { SweepstakesPage } from 'pages/Sweepstakes/SweepstakesPage'
import { LicensedEstablishmentUsersPage } from 'pages/LicensedEstablishmentUsers/LicensedEstablishmentUsersPage'
import { LicensedEstablishmentDetailsPage } from 'pages/LicensedEstablishmentDetailsPage/LicensedEstablishmentDetailsPage'
import { pathPermissions } from './pathPermissions'
import { PatronActivityPage } from 'pages/PlayerPaybackReports/PatronActivityStats/PatronActivityPage'
import { CreditsWageredByHourPage } from 'pages/GamingReports/CreditsWageredByHour/CreditsWageredByHourPage'
import * as _ from 'lodash'
import { ProtectedLayout } from './ProtectedLayout'
import { RewardProgramDetailPage } from 'pages/Rewards/RewardProgramDetailPage'
import { RewardProgramRedemptionPage } from 'pages/Rewards/RewardProgramRedemptionPage'
import { CreditsWageredByMachinePage } from 'pages/GamingReports/CreditsWageredByMachine/CreditsWageredByMachinePage'
import { VgtSnapshotPage } from 'pages/GamingReports/VgtSnapshot/VgtSnapshotPage'
import { VgtTrendsPage } from 'pages/GamingReports/VgtTrends/VgtTrendsPage'
import { CreditsWageredHourlyTrendsPage } from 'pages/GamingReports/CreditsWageredHourlyTrends/CreditsWageredHourlyTrendsPage'
import { TrailingNtiTrendsPage } from 'pages/GamingReports/TrailingNtiTrends/TrailingNtiTrendsPage'
import { CreateAccountPage } from 'pages/Login/CreateAccount/CreateAccountPage'
import { WeeklySnapshotLocationSelectPage } from 'pages/GamingReports/WeeklySnapshot/WeeklySnapshotLocationSelectPage'
import { DevelopHome } from 'pages/Development/DevelopHome'
import { RewardProgramActivityLogPage } from 'pages/Rewards/RewardProgramActivityLogPage'
import { SweepstakeOverviewPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/SweepstakeOverviewPage'
import { SweepstakeParticipatingLocationsPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/ParticipatingLocations/SweepstakeParticipatingLocationsPage'
import { SweepstakesAdditionalEnrollmentDetailsPage } from 'pages/Sweepstakes/SweepstakesAdditionalEnrollmentDetailsPage/SweepstakesAdditionalEnrollmentDetailsPage'
import { ManageEnrollLocationsPage } from 'pages/Sweepstakes/SweepstakesEnrollLocationsPage/ManageEnrollLocationsPage'
import { SweepstakesReviewEnrollmentPage } from 'pages/Sweepstakes/SweepstakesReviewEnrollment/SweepstakesReviewEnrollmentPage'
import { RewardCatalogDetailPage } from 'pages/Rewards/RewardCatalogDetailPage'
import { SweepstakesWinnersPage } from 'pages/Sweepstakes/SweepstakesDetailsPage/SweepstakesWinnersPage/SweepstakesWinnersPage'
import { FlexPayPage } from 'pages/FlexPay/FlexPayPage'
import { PatronCheckInsPage } from 'pages/PlayerPaybackReports/CheckIns/PatronCheckInsPage'
import { PatronListPage } from 'pages/PlayerPaybackReports/PatronList/PatronListPage'
import { RewardRedemptionsPage } from 'pages/PlayerPaybackReports/RewardRedemptions/RewardRedemptionsPage'
import { NewLEConnectUserPage } from 'pages/LicensedEstablishmentUsers/NewLEConnectUserPage'
import { PlayerPaybackReportsOverview } from 'pages/PlayerPaybackReports/PlayerPaybackReportsOverview'
import { LicensesLicensedEstablishmentPage } from 'pages/LicensedEstablishmentLicenses/LicensesLicensedEstablishmentPage'
import { JJNewsPage } from 'pages/JJNews/JJNewsPage'
import { ContactUsPage } from 'pages/ContactUs/ContactUsPage'
import { GamingReportsPage } from 'pages/GamingReports/GamingReportsPage'
import { GamingReportsOverview } from 'pages/GamingReports/GamingReportsOverview'
import { PlayerPaybackReportsPage } from 'pages/PlayerPaybackReports/PlayerPaybackReportsPage'

const pathsToPages: Record<Path, JSX.Element> = {
  '/': <LoginPage />,
  '/CreateAccount': <CreateAccountPage />,
  '/Home': <HomePage />,
  '/Development/Home': <DevelopHome />,
  '/Compliance': <LicensesLicensedEstablishmentPage />,
  '/ContactUs': <ContactUsPage />,
  '/PatronAccounts': <PatronAccountsPage />,
  '/PlayerPayback': <HomePage />,
  '/Reports': <HomePage />,
  '/GamingReports': <GamingReportsPage />,
  '/GamingReports/GamingReportsOverview': <GamingReportsOverview />,
  '/GamingReports/GamingReportsPage': <GamingReportsPage />,
  '/GamingReports/WeeklySnapshotLocationSelect': (
    <WeeklySnapshotLocationSelectPage />
  ),
  '/GamingReports/WeeklySnapshot': <WeeklySnapshotTablePage />,
  '/GamingReports/CreditsWageredByMachine': <CreditsWageredByMachinePage />,
  '/GamingReports/CreditsWageredByHour': <CreditsWageredByHourPage />,
  '/GamingReports/VgtSnapshot': <VgtSnapshotPage />,
  '/GamingReports/VgtTrends': <VgtTrendsPage />,
  '/GamingReports/CreditsWageredHourlyTrends': (
    <CreditsWageredHourlyTrendsPage />
  ),
  '/GamingReports/TrailingNtiTrends': <TrailingNtiTrendsPage />,
  '/PlayerPaybackReports': <PlayerPaybackReportsPage />,
  '/PlayerPaybackReports/PlayerPaybackReportsOverview': (
    <PlayerPaybackReportsOverview />
  ),
  '/PlayerPaybackReports/PlayerPaybackReportsPage': (
    <PlayerPaybackReportsPage />
  ),
  '/PlayerPaybackReports/PatronCheckins': <PatronCheckInsPage />,
  '/PlayerPaybackReports/PatronList': <PatronListPage />,
  '/PlayerPaybackReports/PatronRewardRedemptions': <RewardRedemptionsPage />,
  '/PlayerPaybackReports/PatronActivity': <PatronActivityPage />,
  '/Locations': <LocationsPage />,
  '/FlexPay': <FlexPayPage />,
  '/JJNews': <JJNewsPage />,
  '/Rewards': <RewardsPage />,
  '/Promotions': <PromotionsPage />,
  '/Promotions/:id': <PromotionDetailsPage />,
  '/Sweepstakes': <SweepstakesPage />,
  '/Sweepstakes/:id/Overview': <SweepstakeOverviewPage />,
  '/Sweepstakes/:id/Winners': <SweepstakesWinnersPage />,
  '/Sweepstakes/:id/ParticipatingLocations': (
    <SweepstakeParticipatingLocationsPage />
  ),
  '/Sweepstakes/:id/EnrollLocations': <ManageEnrollLocationsPage />,
  '/Sweepstakes/:id/AdditionalEnrollmentDetails': (
    <SweepstakesAdditionalEnrollmentDetailsPage />
  ),
  '/Sweepstakes/:id/ReviewLocationsToEnroll': (
    <SweepstakesReviewEnrollmentPage />
  ),
  '/LicensedEstablishmentUsers': <LicensedEstablishmentUsersPage />,
  '/LicensedEstablishmentUsers/NewLEConnectUserPage': <NewLEConnectUserPage />,
  '/LicensedEstablishments/:id': <LicensedEstablishmentDetailsPage />,

  // '/LiveAtLocation': <LiveAtLocationPage />,
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId':
  //   <PatronDetailsPage />,
  // '/LiveAtLocation/PatronDetails/:id': <PatronDetailsPage />,
  '/RewardsProgram/:id': <RewardProgramDetailPage />,
  // '/RewardsProgram/:id/Settings': <RewardProgramSettingsPage />,
  '/RewardsProgram/:id/Redemption': <RewardProgramRedemptionPage />,
  '/RewardsProgram/:id/ActivityLog': <RewardProgramActivityLogPage />,
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId': (
    <RewardCatalogDetailPage />
  ),
}

const [publicPaths, protectedPaths] = _.partition(
  allPaths,
  (path) => pathPermissions[path] === 'always'
)

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {publicPaths.map((path) => (
        <Route key={path} path={path} element={pathsToPages[path]} />
      ))}

      <Route element={<ProtectedLayout />}>
        {protectedPaths.map((path) => (
          <Route key={path} path={path} element={pathsToPages[path]} />
        ))}
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </>
  )
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
