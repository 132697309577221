import { Box, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { getLEPermissions } from 'src/navigation/pathPermissions'

export const PlayerPaybackReportsOverview = () => {
  const meQuery = useGetMe()

  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )

  if (meQuery.isPending) {
    return <ActivityIndicator />
  }

  if (meQuery.isError) {
    return <ErrorIndicator />
  }

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    selectedCorporateAccountId
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '16px',
        borderWidth: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        px: '16px',
        py: '16px',
        mt: '1px',
      }}
    >
      {!lePermissions?.[0]?.reportsNonFinancialPermission &&
      !lePermissions?.[0]?.reportsFinancialPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view reports. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          {!licensedEstablishments || licensedEstablishments.length === 0 ? (
            <NoDataIndicator
              imageSource={NoPatronsCheckedInIcon}
              noDataMessage="Cannot view reports because there are no locations available for this corporate account"
            />
          ) : (
            <Typography variant="body-2">
              Select a Location and then select a report tab to see the
              Locations data.
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}
