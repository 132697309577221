import { Box } from '@mui/material'
import { Page } from 'components/Page'

import { PageHeader } from 'components/Shared/PageHeader'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'

export const JJNewsPage = () => {
  // const meQuery = useGetMe()
  // const currentCorporateAccountId =
  //   useCurrentCorporateAccountStore().currentCorporateAccountId

  // // Get the LE permissions
  // const lePermissions = getLEPermissions(
  //   meQuery?.data?.licensedEstablishments,
  //   meQuery?.data?.user?.licensedEstablishments,
  //   currentCorporateAccountId
  // )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="J&J News"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      <NoDataIndicator
        noImage
        noDataMessage="Your current permissions do not allow access to view locations. To obtain access, please contact your Account Manager for assistance."
      />
    </Page>
  )
}
