import { Box } from '@mui/material'
import { CorporateAccountsTreeDataGrid } from 'components/TreeDataGrid/CorporateAccountsTreeDataGrid'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { useGetMe, type GetMeData } from 'hooks/api/useGetMe'
import * as _ from 'lodash'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { PageHeader } from 'components/Shared/PageHeader'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { getLEPermissions } from 'src/navigation/pathPermissions'

interface AccountDataGridProps {
  data: GetMeData
}

const AccountDataGrid = ({ data }: AccountDataGridProps) => {
  const currentCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const corporateAccount = _.compact(
    data.corporateAccounts?.filter((ca) => ca.id === currentCorporateAccountId)
  )
  const organizations = _.compact(data.organizations)
  const licensedEstablishments = _.compact(data.licensedEstablishments)

  return (
    <Box pt="30px">
      <CorporateAccountsTreeDataGrid
        corporateAccounts={corporateAccount}
        organizations={organizations}
        licensedEstablishments={licensedEstablishments}
      />
    </Box>
  )
}

export const LocationsPage = () => {
  const meQuery = useGetMe()
  const currentCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    currentCorporateAccountId
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Locations"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!lePermissions?.[0]?.locationsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view locations. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          {meQuery.data && (
            <AccountDataGrid
              key={currentCorporateAccountId}
              data={meQuery.data}
            />
          )}
        </>
      )}
    </Page>
  )
}
