import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { ActivityButton } from 'components/ActivityButton'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import Markdown from 'react-markdown'
import { useNavigate, useParams } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import { useLayout } from 'hooks/useLayout'
import { CorporateAccountsTreeDataGrid } from 'components/TreeDataGrid/CorporateAccountsTreeDataGrid'
import { useGetCorporateAccountsOrganizationsAndLesFromLeList } from 'hooks/api/useGetCorporateAccountsAndOrganizationsFromLeList'
import { useMemo, useState } from 'react'
import { ConfirmEnrollmentModal } from './ConfirmEnrollmentModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { type GridColDef } from '@mui/x-data-grid'
import {
  dataTableAddressFormatter,
  dataTableLicenseNumberFormatter,
  formatDateTime,
  pluralize,
} from 'utils/util'
import { compact, difference, get, isEmpty, last, concat } from 'lodash'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { useManageParticipatingLocations } from 'hooks/api/useManageParticipatingLocations'
import {
  type EnrollmentDetailsType,
  useSweepstakeParticipatingLocationsAndEnrollmentAgreement,
} from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { type ContestLeRequest } from 'src/types/api'
import './styles.css'
import { useSnackbar } from 'stores/useSnackbar'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useQueryClient } from '@tanstack/react-query'

export const defaultColumns = (isMobile: boolean): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Account Name',
      flex: 1,
    },
    {
      field: 'licenseNumber',
      headerName: 'License #',
      flex: 0.5,
      valueFormatter: dataTableLicenseNumberFormatter(isMobile),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p>
            {isEmpty(params.formattedValue) && !isALicensedEstablishment
              ? '-'
              : `#${String(params.formattedValue)}`}
          </p>
        )
      },
    },
    {
      field: 'addresses',
      headerName: 'Address',
      flex: 1,
      valueFormatter: dataTableAddressFormatter(true),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
            {params.formattedValue === 'Unspecified Address' &&
            !isALicensedEstablishment
              ? '-'
              : params.formattedValue}
          </p>
        )
      },
    },
  ]
}

export const SweepstakesReviewEnrollmentPage = () => {
  const qc = useQueryClient()
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const navigate = useNavigate()
  const setMessage = useSnackbar((x) => x.setMessage)
  const { isMobile } = useLayout()
  const { currentCorporateAccountName } = useCurrentCorporateAccountStore()
  const {
    enrollmentDetails,
    setEnrollmentDetails,
    participatingLocations: participatingIds,
  } = useSweepstakeParticipatingLocationsAndEnrollmentAgreement()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const onConfirmModalClose = () => setIsConfirmModalOpen(false)

  const { mutateAsync: postManageParticipatingLocations } =
    useManageParticipatingLocations({
      onError: () => {
        setMessage('An error occurred when enrolling locations.', 'error')
      },
      onSuccess: async () => {
        qc.clear()
        setMessage('Successfully enrolled locations.')
        await navigate(`/Sweepstakes/${sweepstakeId}/ParticipatingLocations`)
      },
    })

  const { isPending: sweepstakeIsPending, data: sweepstake } =
    useGetSweepstakeById({ sweepstakeId })

  const enrolledIds = sweepstake?.licensedEstablishments?.map((x) => x.id) ?? []
  const selectedIds = useMemo(
    () => difference(participatingIds, enrolledIds),
    [participatingIds, enrolledIds]
  )

  const {
    isPending,
    corporateAccounts,
    licensedEstablishments: selectedLes,
    organizations,
  } = useGetCorporateAccountsOrganizationsAndLesFromLeList({
    leIds: selectedIds,
    enabled: sweepstake !== undefined,
  })

  const allLicensedEstablishments = compact(
    concat(selectedLes, sweepstake?.licensedEstablishments)
  )

  if (isPending || sweepstakeIsPending) {
    return <ActivityIndicator />
  }

  const sweepstakeGuideline =
    sweepstake?.sweepstakeData?.participationGuidelines

  const onConfirm = async () => {
    const { usePrimaryContact, email, notes, signageOne, signageTwo } =
      enrollmentDetails as EnrollmentDetailsType

    const req: ContestLeRequest[] = selectedIds.map((leId) => {
      return {
        emailAddress: usePrimaryContact
          ? last(allLicensedEstablishments.find((x) => x.id === leId)?.contacts)
              ?.email
          : email,
        notes,
        signage1: signageOne === 'true',
        signage2: signageTwo === 'true',
        agreement: true,
        contestId: sweepstakeId,
        licensedEstablishmentId: leId,
      }
    })

    await postManageParticipatingLocations({
      contestId: sweepstakeId,
      req,
    })
  }

  return (
    <Page
      header={
        <>
          <PageHeader
            title="Review Enrollment Details"
            isSecondary={true}
            backText="Additional Enrollment Details"
            backPath={`/Sweepstakes/${sweepstakeId}/AdditionalEnrollmentDetails`}
          >
            <Typography
              variant="body-1"
              color="rgba(41, 43, 38, 0.60)"
              paddingTop="12px"
              paddingBottom="24px"
            >
              Prior to choosing &apos;Enroll&apos;, carefully review your
              selection to participate in the sweepstakes, confirming all
              information is accurate.
            </Typography>
          </PageHeader>
          <Divider />
        </>
      }
      footer={
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          columnGap={2}
        >
          <Box width={'100%'} alignContent={'center'}>
            <Typography variant="body-1" color={'text.secondary'}>
              {pluralize(
                difference(participatingIds ?? [], enrolledIds).length ?? 0,
                'Licensed Establishment'
              )}
              Selected to Enroll in Sweepstakes
            </Typography>
          </Box>
          <Button
            variant="text"
            onClick={async () => {
              setEnrollmentDetails(undefined)
              await navigate(
                `/Sweepstakes/${sweepstakeId}/ParticipatingLocations`
              )
            }}
          >
            Cancel
          </Button>
          <ActivityButton
            active={false}
            // active={participatingLocationsMutation.isPending}
            variant="contained"
            disabled={false}
            onClick={() => {
              setIsConfirmModalOpen(true)
            }}
          >
            Enroll
          </ActivityButton>
        </Box>
      }
    >
      <ConfirmEnrollmentModal
        isModalOpen={isConfirmModalOpen}
        onClose={onConfirmModalClose}
        onConfirm={onConfirm}
        sweepstakeName={String(sweepstake?.name)}
      />

      <Typography variant="h3" sx={{ paddingBottom: 2 }}>
        My Enrollable {currentCorporateAccountName} Locations
      </Typography>

      <CorporateAccountsTreeDataGrid
        columns={defaultColumns(isMobile)}
        corporateAccounts={corporateAccounts}
        organizations={organizations}
        licensedEstablishments={selectedLes}
        getRowClassName={() => 'white-background'}
        hideFooter={allLicensedEstablishments.length <= 20}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        hideFooterSelectedRowCount
        pageSizeOptions={[20, 75, 100]}
      />

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" paddingBottom={1}>
          Location Enrollment
        </Typography>
        <Typography variant="body-1" color="text.secondary">
          If you wish to modify the email address or notes, revisit the previous
          page and edit the extra enrollment details, or proceed with submitting
          this enrollment form and make edits directly on the enrollment form of
          the specific location you&apos;d like to adjust.
        </Typography>
        <Box
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Typography variant="body-1" color="text.secondary">
            Winner Notification Email Address{' '}
          </Typography>
          {selectedLes.map((le) => {
            return (
              <Typography key={le.id}>
                {le.name} (#{le.licenseNumber}):{' '}
                {enrollmentDetails?.usePrimaryContact
                  ? last(le.contacts)?.email
                  : enrollmentDetails?.email}
              </Typography>
            )
          })}

          <Box sx={{ paddingTop: '40px' }}>
            <Typography variant="body-1" color="text.secondary">
              Notes
            </Typography>
            <Typography variant="body-1">
              {isEmpty(enrollmentDetails?.notes)
                ? '-'
                : enrollmentDetails?.notes}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" paddingBottom={1}>
          Sweepstakes Signage
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Box>
            <Typography variant="body-1" color="text.secondary">
              Decal
            </Typography>
            <Typography variant="body-1">
              {enrollmentDetails?.signageOne === 'true' ? 'Yes' : 'No'}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body-1" color="text.secondary">
              Banner
            </Typography>

            <Typography variant="body-1">
              {enrollmentDetails?.signageTwo === 'true' ? 'Yes' : 'No'}
            </Typography>
          </Box>
          <Box width={isMobile ? '80px' : '190px'} />
        </Stack>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" paddingBottom={1}>
          Sweepstakes Overview
        </Typography>
        <Stack
          direction={'column'}
          sx={{
            p: '24px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Stack
            direction={'row'}
            spacing={'20%'}
            justifyContent={'space-between'}
            width={'94%'}
          >
            <Box>
              <Typography variant="body-1" color="text.secondary">
                Sweepstakes Name
              </Typography>
              <Typography variant="body-1">{sweepstake?.name}</Typography>
            </Box>
            <Box>
              <Typography variant="body-1" color="text.secondary">
                Start Date
              </Typography>
              <Typography variant="body-1">
                {formatDateTime(sweepstake?.startDate, 'P p')}
              </Typography>
            </Box>
            <Box pr={'24px'}>
              <Typography variant="body-1" color="text.secondary">
                End Date
              </Typography>
              <Typography variant="body-1">
                {formatDateTime(sweepstake?.endDate, 'P p')}
              </Typography>
            </Box>
          </Stack>
          <Box py={'24px'}>
            <Typography variant="body-1" color="text.secondary">
              URL
            </Typography>
            <Typography variant="body-1">
              {sweepstake?.url ? sweepstake?.url : '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body-1" color="text.secondary">
              Sweepstakes Description
            </Typography>
            <Typography variant="body-1">{sweepstake?.description}</Typography>
          </Box>
        </Stack>
      </Box>

      <Box sx={{ paddingTop: '40px' }}>
        <Typography variant="h3" paddingBottom={1}>
          Details
        </Typography>

        <Box
          sx={{
            p: '16px',
            borderWidth: 1,
            borderColor: 'grey.300',
            borderRadius: 2,
            ul: { paddingLeft: '24px' },
            marginTop: '8px',
          }}
        >
          <Typography variant="body-1" color="text.secondary">
            Description for Locations to Enroll
          </Typography>
          <Typography variant="body-1" color="text.primary">
            {sweepstakeGuideline ? (
              <Markdown rehypePlugins={[rehypeRaw]}>
                {sweepstakeGuideline.replace(/\n\n/gi, '&nbsp;\n\n')}
              </Markdown>
            ) : (
              '-'
            )}
          </Typography>
        </Box>
      </Box>

      <Box className="pt-10">
        <Typography variant="h3">Agreement</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={true}
              checkedIcon={<CheckCircleIcon style={{ color: '#40cc6a' }} />}
            />
          }
          label={
            <Typography variant="body-1" mt={3}>
              By selecting this check box and submitting this enrollment form
              represents and warrants to Terminal Operator that (a) the person
              executing this Enrollment is an authorized signatory of the Video
              Gaming Location and (b) Video Gaming Location authorizes Terminal
              Operator to deduct the applicable Participation Fee from Net
              Terminal Income as set forth above.
            </Typography>
          }
        />
      </Box>
    </Page>
  )
}
