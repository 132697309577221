import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { StyledMenu } from 'components/StyledMenu/StyledMenu'
import ChevronUp from 'assets/chevron-up.svg'
import { useGetMe } from 'hooks/api/useGetMe'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useLayout } from 'hooks/useLayout'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export const CorporateAccountDetails = () => {
  const navigate = useNavigate()
  const { isMobile } = useLayout()
  const qc = useQueryClient()
  const { data, refetch } = useGetMe()
  const { currentCorporateAccountName, setCurrentCorporateAccount } =
    useCurrentCorporateAccountStore()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showAllAccounts, setShowAllAccounts] = useState(false)
  const open = Boolean(anchorEl)

  const hasNoAccess =
    !data?.corporateAccounts || data?.corporateAccounts.length === 0

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(open ? null : event.currentTarget)
      setShowAllAccounts(currentCorporateAccountName !== null)
    },
    [open, currentCorporateAccountName]
  )

  useEffect(() => {
    if (open) {
      void refetch()
    }
  }, [open, refetch])

  const handleAccountSelect = useCallback(
    async (account: string | null | undefined) => {
      const enrollSweestakeLocationRoute = window.location.href.split('/')[5]
      if (
        [
          'EnrollLocations',
          'AdditionalEnrollmentDetails',
          'ReviewLocationsToEnroll',
        ].includes(enrollSweestakeLocationRoute)
      ) {
        await navigate(
          '/Sweepstakes/' +
            window.location.href
              .split('/Sweepstakes/')[1]
              .replace(enrollSweestakeLocationRoute, 'ParticipatingLocations')
        )
      }

      // If the user is on a Reports tab and selects a different corporate account from the dropdown,
      // take the user back to the Reports Overview tab so the location dropdown gets reset, which resets
      // the location dropdown and tabs, and so the user doesn't see the previous corporate account report data
      if (window.location.href.includes('Reports/')) {
        await navigate('/Reports')
      }

      const selectedAccount = data?.corporateAccounts?.find(
        (acc) => acc.name === account
      )
      // reset data for enroll locations page
      qc.clear()

      if (selectedAccount) {
        setCurrentCorporateAccount(
          selectedAccount.name ?? 'Unnamed Account',
          selectedAccount.id
        )

        setShowAllAccounts((prevShowAllAccounts) => !prevShowAllAccounts)
        handleClose()
      }
    },
    [data, navigate, qc, setCurrentCorporateAccount, handleClose]
  )

  const sortedAccounts = useMemo(() => {
    return data?.corporateAccounts
      ?.slice()
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
  }, [data])

  useEffect(() => {
    const firstAccount = sortedAccounts?.[0]

    if (firstAccount && currentCorporateAccountName === undefined) {
      setCurrentCorporateAccount(
        firstAccount.name ?? 'Unnamed Account',
        firstAccount.id
      )
    }
  }, [sortedAccounts, currentCorporateAccountName, setCurrentCorporateAccount])

  return !hasNoAccess ? (
    <div
      onClick={toggleMenu}
      className="flex flex-row justify-start gap-1 pl-6"
    >
      <Typography
        component={'span'}
        variant="body-2"
        display={
          isMobile &&
          currentCorporateAccountName &&
          currentCorporateAccountName.length > 10
            ? ['flex', 'flex', 'flex']
            : ['none', 'flex', 'flex']
        }
        alignItems="center"
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {!isMobile && (
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
              },
            }}
          >
            <span
              style={{
                borderRight: '1px solid #292B2699',
                height: '16px',
                marginRight: '22px',
              }}
            ></span>
          </Box>
        )}
        {isMobile &&
        currentCorporateAccountName &&
        currentCorporateAccountName.length > 15
          ? `${currentCorporateAccountName.substring(0, 15)}...`
          : currentCorporateAccountName ?? 'Select an Account'}
      </Typography>
      <Stack alignItems="center" p={1}>
        {open ? (
          <img
            alt="Closed Corporate Account Menu"
            src={ChevronUp}
            width="20"
            height="20"
          />
        ) : (
          <img
            alt="Opened Corporate Account Menu"
            src={ChevronUp}
            width="20"
            height="20"
            className="rotate-180"
          />
        )}
      </Stack>
      <StyledMenu
        id="appbar-corporate-account-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isMobile ? 'center' : 'bottom',
          horizontal: isMobile ? 'center' : 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? 'top' : 'center',
          horizontal: isMobile ? 'center' : 'left',
        }}
        sx={{
          marginTop: isMobile ? '0' : '16px',
        }}
      >
        <List
          sx={{
            width: isMobile ? '300px' : '400px',
            maxHeight: '400px',
            overflow: 'auto',
            paddingY: 0,
          }}
        >
          {sortedAccounts?.map((account, index) => (
            <React.Fragment key={account.id.toString()}>
              {index > 0 && <Divider sx={{ width: '100%' }} />}
              <ListItem
                onClick={async () => await handleAccountSelect(account.name)}
              >
                <ListItemButton
                  sx={{
                    backgroundColor:
                      account.name === currentCorporateAccountName
                        ? 'rgba(204, 32, 39, 0.08)'
                        : 'transparent',
                    paddingLeft: '1px',
                    paddingTop: '25px',
                    paddingBottom: '25px',
                    marginLeft: '-10px',
                    marginRight: '-10px',
                    height: '40px',
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body-2"
                        sx={{
                          fontSize: '16px',
                          color:
                            showAllAccounts &&
                            account.name === currentCorporateAccountName
                              ? '#CC2027'
                              : '#2C2C27',
                          fontWeight:
                            showAllAccounts &&
                            account.name === currentCorporateAccountName
                              ? 'bold'
                              : 'normal',
                          mb: -1,
                        }}
                      >
                        {account.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: '14px',
                          whiteSpace: 'pre-line',
                          color: '#2C2C27AD',
                        }}
                      >
                        #{account.id}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </StyledMenu>
    </div>
  ) : null
}
