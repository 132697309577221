import { Box, Button, Stack, Typography } from '@mui/material'
import { Page } from 'components/Page'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { useGetMe } from 'hooks/api/useGetMe'
import { useMemo, useState } from 'react'
import { LEConnectUserList } from './LEConnectUserList'
import { useGetLicensedEstablishmentUsersByCorporateAccount } from 'hooks/api/useGetLicensedEstablishmentUsersByCorporateAccount'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import { type LicensedEstablishmentUser } from 'src/types/api'
import { useNavigate } from 'react-router-dom'
import { getLEPermissions } from 'src/navigation/pathPermissions'

export const LicensedEstablishmentUsersPage = () => {
  const navigate = useNavigate()
  const meQuery = useGetMe()
  const currentCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const {
    isPending,
    isError,
    data: leUsers,
  } = useGetLicensedEstablishmentUsersByCorporateAccount(
    currentCorporateAccountId
  )
  const [filteredData, setFilteredData] =
    useState<LicensedEstablishmentUser[]>()

  useMemo(() => {
    setFilteredData(undefined)
  }, [currentCorporateAccountId])

  // Get the LE permissions
  const lePermissions = getLEPermissions(
    meQuery?.data?.licensedEstablishments,
    meQuery?.data?.user?.licensedEstablishments,
    currentCorporateAccountId
  )

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="J&J Connect Users"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {(meQuery.isPending || isPending) && <ActivityIndicator />}
      {(meQuery.isError || isError) && <ErrorIndicator />}
      {!lePermissions ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view J&J Connect Users. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          <Stack
            direction={{ md: 'row', sm: 'column', xs: 'column' }}
            gap={{ md: 0, sm: 2, xs: 2 }}
            justifyContent={'space-between'}
          >
            <Box sx={{ width: '50%' }}>
              <GridSearchField<'FullLicensedEstablishmentUserDTO'>
                data={leUsers ?? []}
                propsToSearch={['firstName', 'lastName', 'email', 'type']}
                placeholder="Search J&J Connect Users"
                handleFilteredData={setFilteredData}
                fullWidth
                focused
              />
            </Box>
            <Button
              variant="contained"
              onClick={async () => {
                await navigate(
                  '/LicensedEstablishmentUsers/NewLEConnectUserPage'
                )
              }}
            >
              <Typography>Create New J&J Connect User</Typography>
            </Button>
          </Stack>

          <LEConnectUserList users={filteredData ?? leUsers ?? []} />
        </>
      )}
    </Page>
  )
}
